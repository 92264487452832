<template>
	<div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 100%;">
		<div style="display: flex; justify-content: center; flex-direction: column; width: 400px; height: 50%;">
			<el-form :model="form" ref="form" label-width="90px" label-position="left" :rules="rules">
			    <el-form-item label="用户名: " required prop="userName">
			        <el-input v-model="form.userName"></el-input>
			    </el-form-item>

			    <el-form-item label="密码: " required prop="password">
			        <el-input show-password v-model="form.password"></el-input>
			    </el-form-item>

			    <div style="display: flex; justify-content: center;">
			    	<el-form-item label-width="auto">
				       <el-button @click="onbtnCancelClick" style="text-align: center;">取消</el-button>
				    </el-form-item>

				    <el-form-item style="margin-left: 20px;" label-width="auto">
				       <el-button type="primary" style="text-align: center;" @click="onBtnLoginClick('form')">登录</el-button>
				    </el-form-item>
			    </div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import router from "@/routes"
	import { post } from "@/components/Http"

	export default{
		inject: ["login"],
		data(){
			return {
				form: {
					userName: "",
					password: ""
				},

				rules: {
					userName: [
						{ required: true, message: '请输入用户名', trigger: 'blur' }
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' }
					]
				}
			}
		},

		methods: {
			onbtnCancelClick(){
				router.back();
			},

			onBtnLoginClick(formName){
				this.$refs[formName].validate((valid) => {
					if(valid){
						var params = {
							userName: this.form.userName,
							password: this.form.password
						};
						post("/api/pwlogin", params)
						.then(res => {
							if(res.errNo !== 0){
								this.$alert(res.errMsg, "出错啦～", {
									confirmButtonText: "确定",
									callback: action => {

									}
								})
							} else {
								localStorage.setItem("sessionId", res.data.sessionId);
								this.login({
									userName: this.form.userName
								});
								router.back();
							}
						},
						res => {
							console.log(res);
						});
					}
				});
			}
		}
	}
</script>

<style scoped>
	
</style>