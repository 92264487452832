<template>
  <span>
    <div style="position: absolute; top: 0; bottom: 0; right: 0; display: flex; flex-direction: column; justify-content: center;" ref="bar">
      <div style="display: flex; justify-content: flex-end; align-items: center; height: 70px;">
        <div style="width: 200px; height: 200px; margin-right: 30px; z-index: 100; display: flex; align-items: center; justify-content: center; background-color: white; border-radius: 5px;" v-if="barItemShow=='mini'">
          <div style="width: 0; height: 0; border-top: solid transparent 20px; border-left: solid white 20px; border-right: solid transparent 20px; border-bottom: solid transparent 20px; position: absolute; left: 200px"></div>
          <el-image :src="require('@/resource/miniCode.jpeg')" style="width: 90%; height: 90%"></el-image>
        </div>
        <div class="main_bar_item" @mouseover="onBarMiniHover" @mouseout="onBarMinOut">
          <el-image :src="require('@/resource/mainMiniProgram.png')" class="main_bar_item_icon"></el-image>
          <div style="font-size: 12px; margin-top: 5px">小程序</div>
        </div>
      </div>
      
      <div style="display: flex; justify-content: flex-end; align-items: center; height: 70px;">
        <div style="width: 200px; height: 200px; margin-right: 30px; z-index: 100; display: flex; align-items: center; justify-content: center; background-color: white; border-radius: 5px;" v-if="barItemShow=='weixin'">
          <div style="width: 0; height: 0; border-top: solid transparent 20px; border-left: solid white 20px; border-right: solid transparent 20px; border-bottom: solid transparent 20px; position: absolute; left: 200px"></div>
          <el-image :src="contactInfo.qrCode" style="width: 70%; height: auto;"></el-image>
        </div>
        <div class="main_bar_item" @mouseover="onBarWeixinHover" @mouseout="onBarWeixinOut">
          <el-image :src="require('@/resource/mainWeixin.png')" class="main_bar_item_icon"></el-image>
          <div style="font-size: 12px; margin-top: 5px">微信</div>
        </div>
      </div>

      <div style="display: flex; justify-content: flex-end; align-items: center; height: 70px;">
        <div style="width: 200px; height: 200px; margin-right: 30px; z-index: 100; display: flex; align-items: center; justify-content: center; background-color: white; border-radius: 5px;" v-if="barItemShow=='app'">
          <div style="width: 0; height: 0; border-top: solid transparent 20px; border-left: solid white 20px; border-right: solid transparent 20px; border-bottom: solid transparent 20px; position: absolute; left: 200px"></div>
          <el-image :src="require('@/resource/appCode.png')" style="width: 90%; height: 90%"></el-image>
        </div>
        <div class="main_bar_item" @mouseover="onBarAppHover" @mouseout="onBarWeixinOut">
          <el-image :src="require('@/resource/mainApp.png')" class="main_bar_item_icon"></el-image>
          <div style="font-size: 12px; margin-top: 5px">app</div>
        </div>
      </div>
    </div>


    <!-- <div class="bar_mini_arrow">
      
    </div> -->
    
    <router-view v-if="this.$route.path=='/sharetiku5'">
      
    </router-view>
    <div class="background" v-else>
      <div style="display: flex; justify-content: center; margin-top: 15px; height: 30px;">
        <el-image :src="require('@/resource/icon.png')" style="width: 28px; height: 28px; align-self: center;"></el-image>
        <span style="margin-left: 10px; align-self: center; font-weight: 500; font-size: 14px; color: rgba(51, 51, 51, 1)">小包搜题</span>
        <el-tabs v-model="currentPage" @tab-click="onBtnPageClick" style="margin-left: 400px; margin-top: -5px; height: 100%;">
          <el-tab-pane label="首页" name="mainPage"></el-tab-pane>
          <el-tab-pane label="我的制作" name="myMake"></el-tab-pane>
        </el-tabs>

        <el-button round @click="onBtnVipClick" style="align-self: center; background-color: #303D4D; color: rgba(236, 213, 189, 1); width: 100px; height: 100%; font-size: 13px; margin-left: 50px;">
          <el-image :src="require('@/resource/crown.png')" style="height: 15px; width: 15px;"></el-image>
          <span style="margin-left: 13px;">加入VIP</span>
        </el-button>

        <div style="display:flex; margin-left: 20px;" v-if="userInfo.isLogin">
          <div v-if="userInfo.isVip">
            <div class="head_background">
              <el-image :src="userInfo.headimgurl" style="height: 25px; width: 25px; align-self: center; border-radius: 50%; position: absolute; left: 1.5px; bottom: 0.75px" @error="onLoadHeadFail">
              
              </el-image>
            </div>
          </div>

          <el-image :src="userInfo.headimgurl" style="height: 100%; align-self: center; border-radius: 50%;" @error="onLoadHeadFail" v-else></el-image>

          <div v-if="userInfo.isVip">
            <div style="display: flex; align-items: center;">
              <el-image :src="require('@/resource/vip.png')" style="margin-left: 10px; height: 11px; width: 20px; display: block; margin-top: 2px;"></el-image>
              <span style="font-size: 10px; margin-left: 5px;">{{userInfo.vipDate}}</span>
            </div>
            <div style="align-self: center; font-weight: 300; font-size: 15px; color: rgba(51, 51, 51, 1); margin-left: 10px; cursor: pointer;" @click="onBtnUserNameClick">
              {{userInfo.userName}}
            </div>
          </div>
          <div style="display: flex; justify-content: center; align-items: center;" v-else>
            <div style="align-self: center; font-weight: 300; font-size: 15px; color: rgba(51, 51, 51, 1); margin-left: 10px; cursor: pointer;" @click="onBtnUserNameClick">
              {{userInfo.userName}}
            </div>
          </div>
          
        </div>
        <el-button round @click="onBtnLoginClick" style="width: 100px; height: 100%; background-color: rgba(24, 144, 255, 1); margin-left: 30px; align-self: center; font-size: 13px;" v-else>
          <span style="color: white;">登录</span>
        </el-button>
      </div>
      <router-view></router-view>
    </div>
  </span>
  
  
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import axios from 'axios'
import './assets/css/all.css'
import router from './routes'
import { post } from "@/components/Http"
import {contactInfo} from "@/GlobalData"

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}

export default {
  name: 'App',

  provide(){
    return {
      setPage: this.setPage,
      login: this.login,
      getUserInfo: this.getUserInfo,
      userInfo: this.userInfo
    }
  },

  computed: {
    contactInfo: {
      get(){
        return contactInfo;
      }
    }
  },

  created(){
    if(/\/#\/$/.test(window.location.href)){
      router.replace('/home');
    }
    if(/\/$/.test(window.location.href)){
      router.replace('/home');
    }
    
    var sessionId = localStorage.getItem("sessionId");
    if(sessionId){
      var params = {
        sessionId: sessionId
      }
      post("/api/checklogin", params)
      .then(res => {
        this.userInfo.userId = res.data.userId;
        this.userInfo.isLogin = res.data.isLogin;
        this.userInfo.userName = res.data.userName;
        this.userInfo.headimgurl = res.data.headimgurl;
        this.userInfo.isVip = res.data.isVip;
        this.userInfo.vipDate = res.data.vipDate;
      })
      .catch(error => {
        // console.log(error);
      })
    }

  },

  mounted(){
    
  },

  data(){
    return {
      currentPage: "mainPage",
      userInfo: {
        userId: 0,
        isLogin: 0,
        userName: "",
        headimgurl: "",
        isVip: false
      },
      barItemShow: ""
    }
  },

  methods:{
    onBtnPageClick(page) {
      if(page.paneName == "mainPage"){
        router.replace("/home");
      } else{
        if(this.userInfo.isLogin){
          router.replace("/mymake");
        } else {
          router.push("/login");
        }
      }
    },

    onBtnVipClick(){
      router.push("/addvip");
    },

    onBtnLoginClick(){
      router.push("/login");
    },

    onBtnUserNameClick(){
      localStorage.removeItem("sessionId");
      this.userInfo.isLogin = 0;
      this.userInfo.userName = "";
      router.push("/login");
    },

    setPage(name){
      this.currentPage = name;
      this.onBtnPageClick(name);
    },

    login(userInfo){
      this.userInfo.userName = userInfo.userName;
      this.userInfo.isLogin = 1;
    },

    onLoadHeadFail(){
      this.userInfo.headimgurl = require('@/resource/head.png');
    },

    getUserInfo(){
      return this.userInfo;
    },

    onBarMiniHover(){
      this.barItemShow = "mini";
    },
    onBarWeixinHover(){
      this.barItemShow = "weixin";
    },

    onBarAppHover(){
      this.barItemShow = "app";
    },

    onBarMinOut(){
      this.barItemShow = "";
    },

    onBarWeixinOut(){
      this.barItemShow = "";
    },

    onBarAppOut(){
      this.barItemShow = "";
    }
  },
}
</script>

<style scoped>

.head_background{
  width: 28px;
  height: 30px;
  background-image: url('@/resource/vipHead.png');
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: auto;
  position: relative;
}

.background{
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url('resource/background.png');
  overflow: auto;
  background-color: rgba(244, 249, 255, 1);
  display: flex;
  flex-direction: column;
}

.main_bar_item{
  width: 70px; 
  height: 70px;
  display: flex; 
  align-items: center; 
  flex-direction: column; 
  justify-content: center;
  cursor: pointer;
  position: relative;
  background-color: white;
  /*background-color: rgba(24, 144, 255, 1);*/
}

.main_bar_item:hover{
  background-color: rgba(24, 144, 255, 1);
  color: white;
  /*filter: brightness(100);*/
}

.main_bar_item:hover .main_bar_item_icon{
  filter: brightness(100);
}

.main_bar_item_icon{
  width: 30px;
  height: 30px;
  /*margin-top: 30px;*/
}

.bar_mini_arrow {
    width: 0px;
    height: 0px;
    border-top: 100px solid transparent;
    border-right: 100px solid darkred;
    border-bottom: 100px solid transparent;
    border-left: 100px solid transparent;
}

/deep/.el-tabs__item {
  font-size: 15px;
}

</style>
