
import SparkMD5 from "spark-md5";

export default{
    getUrlKey: function (name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
    },

    computeMD5(file){
        var p = new Promise(function(resolve, reject){
            var blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
            chunkSize = 2097152,                             // Read in chunks of 2MB
            chunks = Math.ceil(file.size / chunkSize),
            currentChunk = 0,
            spark = new SparkMD5.ArrayBuffer(),
            fileReader = new FileReader();

            fileReader.onload = function (e) {
              spark.append(e.target.result);                   // Append array buffer
              currentChunk++;

              if (currentChunk < chunks) {
                  loadNext();
              } else {
                  resolve(spark.end());
              }
            };
            fileReader.onerror = function () {
              reject("oops, something went wrong.");
            };
            loadNext();

            function loadNext() {
              var start = currentChunk * chunkSize,
                  end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;

              fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
            }
        });
        return p;
    },

    getHost(){
      var host = "souti.ixyzh.com";
      let test = localStorage.getItem("test");
      if(test > 0){
        host = "lxztest.kinva.cc";
      }
      return host;
    }
}