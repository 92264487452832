<template>
	<div style="display: flex; justify-content: center; margin-top: 60px;">
    <el-image :src="require('@/resource/MakeTiku.png')" style="width: 50px; height: 50px; align-self: center;"></el-image>
    <span style="margin-left: 10px; align-self: center; font-weight: 500; font-size: 28px;">一键制作题库</span>
  </div>
  <div style="margin-top: 20px; display: flex; justify-content: center;">
    <div style="width: 397px; height: 60px; text-align: center; font-weight: 350; color: rgba(51, 51, 51, 1); font-size: 13px;">AI智能一键制作题库111，采取先进的AI技术智能分析上传的文档，并从文档中抽取题目转为我们软件可用题库格式。
    </div>
  </div>
  <div style="display: flex; justify-content: center; margin-top: 20px;">
    <div style="width: 406px; height: 258px; background-color: rgba(244, 249, 255, 1);">
      <div style="width: 100%; height: 100%; background-color: white; border-radius: 20px 20px 20px 20px; display: flex; justify-content: center; align-items: center;">
        <div style="text-align: center;">
          <el-button style="width: 50px; height: 50px;" id="fileChose">
            <el-image :src="require('@/resource/AddFile.png')" style="width: 50px; height: 50px;">
            </el-image>

            <el-button style="width: 50px; height: 50px; position: absolute;" @click.stop="onBtnNoticeVipClick" v-if="!userInfo.isVip || !userInfo.isLogin">
              <el-image :src="require('@/resource/AddFile.png')" style="width: 50px; height: 50px;">
              
              </el-image>
            </el-button>
          </el-button>
          <div style="color: rgba(24, 144, 255, 1); font-size: 20px; font-weight: 400; margin-top: 20px;">点击上传文件</div>
        </div>
      </div>
    </div>
  </div>
  
  <div style="font-weight: 350; font-size: 13px; text-align: center; margin-top: 50px; margin-bottom: 15px;">
    支持格式
  </div>
  <div style="display: flex; justify-content: center;">
    <div style="text-align: center;">
      <div style="width: 34px; height: 34px;">
        <el-image :src="require('@/resource/Word.png')" style="width: 34px; height: 34px;"></el-image>
      </div>
      <div style="font-weight: 400; font-size: 14px;">Word</div>
    </div>
    <div style="text-align: center; margin-left: 40px;">
      <div style="width: 34px; height: 34px;">
        <el-image :src="require('@/resource/Excel.png')" style="width: 34px; height: 34px;"></el-image>
      </div>
      <div style="font-weight: 400; font-size: 14px;">Excel</div>
    </div>
    <div style="text-align: center; margin-left: 40px;">
      <div style="width: 34px; height: 34px;">
        <el-image :src="require('@/resource/TXT.png')" style="width: 34px; height: 34px;"></el-image>
      </div>
      <div style="font-weight: 400; font-size: 14px;">TXT</div>
    </div>
  </div>
</template>

<script>
  import router from '@/routes'
  import tools from '@/components/Tools'
  import { post } from "@/components/Http"
  import plupload from "plupload";
  import { v4 as uuid } from 'uuid';
  import QRCode from "qrcodejs2";

  export default{
    inject: ["setPage", "userInfo"],

    created(){
      let code = tools.getUrlKey("code");
      let sessionId = tools.getUrlKey("sessionId");
      if(undefined != code && null != code){
        let params = {
          code: code
        };

        post("/api/weixin/login", params)
        .then(res => {
          if(res.errNo !== 0){
            this.$alert(res.errMsg, "出错啦～", {
              confirmButtonText: "确定",
              callback: action => {

              }
            })
          } else {
            localStorage.setItem("sessionId", res.data.sessionId);
            window.location.href = "https://souti.ixyzh.com/";
          }
        })
      } else if(undefined != sessionId && null != sessionId){
        if(null != window.opener){
          window.opener.postMessage({sessionId: sessionId}, "http://localhost:8080");
          window.open('','_self').close();
          return;
        }
      }
    },

    data(){
      return {
        uploader: null,
        fileOptions: {
          browse_button: "fileChose",
          url: "https://ixyzh-souti.oss-rg-china-mainland.aliyuncs.com",
          // url: "https://hongfengye.oss-rg-china-mainland.aliyuncs.com",
          flash_swf_url: "script/Moxie.swf",
          silverlight_xap_url: "script/Moxie.xap",
          // chunk_size: "37mb",
          max_retries: 3,
          unique_names: true,
          multi_selection: false,
          views: {
            list: true,
            thumbs: true, // Show thumbs
            active: "thumbs"
          },
          filters: {
            max_file_size: "20480mb", //最大上传的文件
            prevent_duplicates: true, //不允许选取重复文件
            mime_types: [
              { title : "txt files", extensions : "txt" },
              { title : "word files", extensions : "docx" },
              { title : "word files", extensions : "doc" },
              { title : "excel files", extensions : "xls" },
              { title : "excel files", extensions : "xlsx" },
              { title : "pdf files", extensions : "pdf" }
            ]
          },
          multipart_params: {
            'key' : "hello.jpg",
            'policy': "eyJleHBpcmF0aW9uIjoiMjAyMy0xMC0wMVQwNDo1MTo0NC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==",
            // 'OSSAccessKeyId': "LTAI5tFDjV3PzudmNw9n8wif", 
            'OSSAccessKeyId': "LTAI5tDAao6EuYpegotv3fhG", 
            'success_action_status' : '200', //让服务端返回200,不然，默认会返回204
            // 'callback-Var': "eyJ4OmFiYyI6ImhlbGxvIn0=",
            'callback' : "eyJjYWxsYmFja1VybCI6Imh0dHBzOlwvXC9zb3V0aS5peHl6aC5jb21cLyIsImNhbGxiYWNrQm9keSI6ImZpbGVuYW1lPSR7b2JqZWN0fSZzaXplPSR7c2l6ZX0mbWltZVR5cGU9JHttaW1lVHlwZX0maGVpZ2h0PSR7aW1hZ2VJbmZvLmhlaWdodH0md2lkdGg9JHtpbWFnZUluZm8ud2lkdGh9IiwiY2FsbGJhY2tCb2R5VHlwZSI6ImFwcGxpY2F0aW9uXC94LXd3dy1mb3JtLXVybGVuY29kZWQifQ==",
            'hello': 'world',
            'signature': "JLdI/VHWM7xap/qbPkVdfT3GFmk=",
          }
        }
      }
    },

    mounted(){
      this.uploader = new plupload.Uploader(this.fileOptions);
      this.uploader.init();
      this.uploader.bind("FilesAdded", this.FilesAdded);
      this.uploader.bind("BeforeUpload", this.BeforeUpload);
      //上传成功监听
      this.uploader.bind("FileUploaded", this.FileUploaded);
    },

    computed:{
      
    },

    methods: {
      suffix(filename) {
        let pos = filename.lastIndexOf('.')
        var suffix = ''
        if (pos != -1) {
            suffix = filename.substring(pos)
        }
        return suffix;
      },

      onRequestFile(param){
        param.onSuccess("kkk");
      },

      FilesAdded(uploader, files) {
        tools.computeMD5(files[0].getNative())
        .then(md5 => {
          let sessionId = localStorage.getItem("sessionId");
          return post("/api/tiku/getsigniture", {
            filename: files[0].name,
            md5: md5,
            platform: 'web'
          }, {headers: {
            Sessionid: sessionId
          }})
          
        })
        .then(res => {
          if(res.errNo > 0){
            this.$message.error(res.errMsg);
            uploader.files = [];
            return;
          }
          if(res.data.isExists){
            uploader.files = [];
            this.setPage("myMake");
          } else {
            let path = res.data.path;
            this.fileOptions.multipart_params.key = path;
            this.fileOptions.multipart_params.policy = res.data.policy;
            this.fileOptions.multipart_params.callback = res.data.callback2;
            this.fileOptions.multipart_params.signature = res.data.signature;

            this.fileOptions.multipart_params["x:userid"] = res.data.callbackVar.userId;
            this.fileOptions.multipart_params["x:oldname"] = res.data.callbackVar.oldName;
            this.fileOptions.multipart_params["x:md5"] = res.data.callbackVar.md5;
            this.fileOptions.multipart_params["x:modelid"] = res.data.callbackVar.modelId;
            this.fileOptions.multipart_params["x:platform"] = res.data.callbackVar.platform;


            this.uploader.setOption({
              multipart_params: this.fileOptions.multipart_params
            });
            this.uploader.start(); 
          }
        })
        .catch(error => {
          this.$message.error(error);
          uploader.files = [];
        })
      },

      onBtnNoticeVipClick(){
        if(!this.userInfo.isLogin){
          router.push("/login");
          return;
        }

        if(!this.userInfo.isVip){
          router.push("/purchase");
          return;
        }
      },

      BeforeUpload(uploader, file){
        
        
      },

      FileUploaded(uploader, file, responseObject) {
        uploader.files = [];
        this.setPage("myMake");
      },

    }
  }
</script>

<style scoped>

</style>
















