// globalMixin.js

import { get } from "@/components/Http"
import { reactive, readonly } from "vue";

const stateContactInfo = reactive({ channel: "", qrCode: "", customerLink: "", corpId: ""});
export var contactInfo = readonly(stateContactInfo);

get("/api/contact/getcontactinfo")
.then(res => {
  stateContactInfo.channel = res.data.channel;
  stateContactInfo.qrCode = res.data.qrCode;
  stateContactInfo.customerLink = res.data.customerLink;
  stateContactInfo.corpId = res.data.corpId;
})