import { createApp } from 'vue'
import Vue from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'


// 创建路由
// const router = new VueRouter({
//     routes:routes,
//     mode:"history",//去掉#
// })


import router from './routes'

const app = createApp(App)
app.use(ElementPlus)
app.use(router)
app.mount('#app')

