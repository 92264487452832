<template>
	<div style="height: 100%; display: flex; flex-direction: column; align-items: center;">
		<div class="vipBackground">
			<div style="color: rgba(236, 213, 189, 1); margin-top: 80px; font-size: 30px; font-weight: 500">
				加入一键制作题库VIP
			</div>

			<el-button round @click="onBtnVipClick" style="background-color: rgba(236, 213, 189, 1); width: 160px; height: 45px; border-radius: 100px 100px 100px 100px; margin-top: 30px; ">
				<div style="font-weight: 450; color: rgba(51, 51, 51, 1); font-size: 20px;">
					立即加入
				</div>
			</el-button>
		</div>

		<div style="width: 53%; height: 55%; background-color: white; margin-top: -110px; border-radius: 15px; display: flex;">
				<div style="width: 50%; height: 100%; box-shadow: inset -10px 0px 10px -10px rgba(0, 0, 0, 0.04); display: flex; flex-direction: column; align-items: center;">
					<div style="background-color: rgba(24, 144, 255, 1); width: 100%; height: 45px; border-radius: 15px 0 0 0; display: flex; justify-content: center; align-items: center; font-size: 23px; font-weight: 400; color: white;">
						免费
					</div>

					<div style="width: 100%; height: 62px; display: flex;">
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 330; line-height: 62px; text-align: left; padding-left: 60px;">
							基础制作题库
						</div>
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 500; line-height: 62px; text-align: right; padding-right: 60px;">
							无限
						</div>
					</div>

					<div style="background-color: rgba(223, 238, 254, 1); width: 75%; height: 1px;"></div>

					<div style="width: 100%; height: 62px; display: flex;">
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 330; line-height: 62px; text-align: left; padding-left: 60px;">
							智能制作题库
						</div>
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 500; line-height: 62px; text-align: right; padding-right: 60px;">
							无
						</div>
					</div>

					<div style="background-color: rgba(223, 238, 254, 1); width: 75%; height: 1px;"></div>

					<div style="width: 100%; height: 62px; display: flex;">
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 330; line-height: 62px; text-align: left; padding-left: 60px;">
							题库云备份
						</div>
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 500; line-height: 62px; text-align: right; padding-right: 60px;">
							无
						</div>
					</div>

					<div style="background-color: rgba(223, 238, 254, 1); width: 75%; height: 1px;"></div>

					<div style="width: 100%; height: 62px; display: flex;">
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 330; line-height: 62px; text-align: left; padding-left: 60px;">
							APP广告
						</div>
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 500; line-height: 62px; text-align: right; padding-right: 60px;">
							有
						</div>
					</div>

					<div style="background-color: rgba(223, 238, 254, 1); width: 75%; height: 1px;"></div>

					<div style="width: 100%; height: 62px; display: flex;">
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 330; line-height: 62px; text-align: left; padding-left: 60px;">
							私有分享
						</div>
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 500; line-height: 62px; text-align: right; padding-right: 60px;">
							可下载不可分享
						</div>
					</div>
				</div>

				<div style="width: 50%; height: 100%; opacity: 1; display: flex; flex-direction: column; align-items: center;">
					<div style="background-color: rgba(236, 213, 189, 1); width: 100%; height: 45px; border-radius: 0 15px 0 0; display: flex; justify-content: center; align-items: center; font-size: 23px; font-weight: 400; color: black;">
						VIP
					</div>

					<div style="width: 100%; height: 62px; display: flex;">
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 330; line-height: 62px; text-align: left; padding-left: 60px;">
							基础制作题库
						</div>
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 500; line-height: 62px; text-align: right; padding-right: 60px; color: rgba(24, 144, 255, 1);">
							无限
						</div>
					</div>

					<div style="background-color: rgba(223, 238, 254, 1); width: 75%; height: 1px;"></div>

					<div style="width: 100%; height: 62px; display: flex;">
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 330; line-height: 62px; text-align: left; padding-left: 60px;">
							智能制作题库
						</div>
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 500; text-align: right; padding-right: 60px; color: rgba(24, 144, 255, 1); display: flex; justify-content: right; align-items: center;">
							<el-image :src="imgTick" style="height: 15.75px; width: 15.75px; margin-right: 10px;"></el-image>
							无限
						</div>
					</div>

					<div style="background-color: rgba(223, 238, 254, 1); width: 75%; height: 1px;"></div>

					<div style="width: 100%; height: 62px; display: flex;">
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 330; line-height: 62px; text-align: left; padding-left: 60px;">
							题库云备份
						</div>
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 500; text-align: right; padding-right: 60px; color: rgba(24, 144, 255, 1); display: flex; justify-content: right; align-items: center;">
							<el-image :src="imgTick" style="height: 15.75px; width: 15.75px; margin-right: 10px;"></el-image>
							7天
						</div>
					</div>

					<div style="background-color: rgba(223, 238, 254, 1); width: 75%; height: 1px;"></div>

					<div style="width: 100%; height: 62px; display: flex;">
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 330; line-height: 62px; text-align: left; padding-left: 60px;">
							APP广告
						</div>
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 500; text-align: right; padding-right: 60px; color: rgba(24, 144, 255, 1); display: flex; justify-content: right; align-items: center;">
							<el-image :src="imgTick" style="height: 15.75px; width: 15.75px; margin-right: 10px;"></el-image>
							无
						</div>
					</div>

					<div style="background-color: rgba(223, 238, 254, 1); width: 75%; height: 1px;"></div>

					<div style="width: 100%; height: 62px; display: flex;">
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 330; line-height: 62px; text-align: left; padding-left: 60px;">
							私有分享
						</div>
						<div style="width: 50%; height: 100%; font-size: 15px; font-weight: 500; text-align: right; padding-right: 60px; color: rgba(24, 144, 255, 1); display: flex; justify-content: right; align-items: center;">
							<el-image :src="imgTick" style="height: 15.75px; width: 15.75px; margin-right: 10px;"></el-image>
							可下载可分享
						</div>
					</div>
				</div>
		</div>
	</div>
	
</template>

<script type="text/javascript">
	import router from '@/routes'

	export default {
		data(){
			return {
				imgTick: require('../resource/tick.png')
			}
		},

		methods:{
			onBtnVipClick(){
				router.push("/purchase");
			}
		}
	}
	
</script>

<style scoped>

.vipBackground{
	width: 100%;
	height: 50%; 
	display: flex; 
	flex-direction: column; 
	align-items: center; 
	background-image: url('../resource/VipBackground.png'); 
	background-size: 100%; 
	background-color: black;
	margin-top: 15px;
}
	
</style>