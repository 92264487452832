<template>
	<div :class="this.tikus.list.length==0 ? 'background_empty' : 'background_not_empty'">

		<modal :show="error.show" title="错误" @hideModal="hideModal">
		    <p>{{error.msg}}</p>
		</modal>

		<modal :show="contactShow" title="问题反馈" @hideModal="hideContact">
		    <el-image :src="contactInfo.qrCode" style="width: 200px; height: auto;"></el-image>
		</modal>

		<div v-if="this.tikus.list.length == 0" style="text-align: center;">
			<el-image :src="imgEmpty" style="width: 150px; height: 122px;"></el-image>
			<div style="color: rgba(153, 153, 153, 1); font-size: 14px; font-weight: 400; margin-top: 20px;">暂无数据</div>
		</div>
		<div  v-else style="width: 100%; height: 100%; display: flex; justify-content: center;">
			<div style="width: 50%;">
				<div style="display: flex; flex-direction: row; align-items: center; margin-top: 80px; margin-bottom: 50px;">
					<div style="font-size: 25px; font-weight: 500;">我的制作</div>
					<el-button style="margin-left: 50px;" @click="onBtnMultiDeleteClick">{{isMultiDelete?"取消删除":"批量删除"}}</el-button>
					<el-button style="margin-left: 20px;" @click="onBtnMultiDeleteOKClick" v-if="isMultiDelete">确认删除</el-button>
					<el-button style="margin-left: 20px;" @click="onBtnMultiDeleteAllClick" v-if="isMultiDelete">当页全选</el-button>
					<el-button style="margin-left: 20px;" @click="onBtnMultiDeleteAllNotClick" v-if="isMultiDelete">全不选</el-button>
				</div>

				<div v-for="(val, index) in tikus.list" :key="index" style="width: 100%; height: 100px; background-color: white; margin-bottom: 20px; border-radius: 10px; display: flex;">
					<div style="width: 50%; height: 100%; display: flex; align-items: center;">
						<div style="margin-left: 50px; width: 100%;">
							<div style="font-weight: 450; font-size: 18px; text-overflow: ellipsis; width: 100%; overflow: hidden;display:-webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;">
								{{val["filename"]}}
							</div>

							<div style="font-weight: 350; font-size: 12px; color: rgba(145, 150, 156, 1); margin-top: 5px;">
								{{val["time"]}}
							</div>
						</div>
					</div>

					<div style="width: 50%; height: 100%; display: flex; align-items: center;" v-if="val['status']==2">
						<div style="display: flex; align-items: center; cursor: pointer;" @click="onBtnDownloadClick(val['id'])">
							<el-image :src="this.imgDownload" style="width: 34px; height: 34px;"></el-image>
							<div style="font-weight: 400; font-size: 12px; margin-left: 15px;">点击下载</div>
						</div>

						<div style="display: flex; align-items: center; cursor: pointer; margin-left: 50px;" @click="onBtnCopyClick(val['id'])">
							<el-image :src="this.imgCopy" style="width: 34px; height: 34px;"></el-image>
							<div style="font-weight: 400; font-size: 12px; margin-left: 15px;">点击复制</div>
						</div>
					</div>
					<div style="width: 50%; height: 100%; display: flex; align-items: center;" v-else-if="val['status']==3">
						<el-image :src="this.imgError" style="width: 22px; height: 22px;"></el-image>
						<div style="font-weight: 400; font-size: 12px; margin-left: 15px; color: red;">转换失败，请重试！</div>

						<div style="margin-left: 20px; font-size: 12px; color: blue; cursor: pointer;" @click="onBtnErrorClick(val['res_msg'])">
							查看错误
						</div>

						<div style="margin-left: 20px; font-size: 12px; color: blue; cursor: pointer;" @click="onBtnContactClick(val['res_msg'])">
							问题反馈
						</div>
					</div>
					<div style="width: 50%; height: 100%; display: flex; align-items: center;" v-else>
						<div style="display: flex; align-items: center;">
							<el-image :src="this.imgLoading" style="width: 25px; height: 25px; -webkit-animation: rotate 0.8s linear infinite;"></el-image>
							<div style="font-weight: 400; font-size: 12px; margin-left: 15px;">正在转化中...</div>
						</div>
					</div>
					<div style="display: flex; align-items: center; margin-right: 20px" v-if="isMultiDelete">
						<el-checkbox v-model="checkList[val.id]"></el-checkbox>
					</div>
					<div style="display: flex; align-items: center; margin-right: 20px" v-else>
						<el-button @click="onBtnDeleteClick(val.id)">删除</el-button>
					</div>
				</div>
			</div>
		</div> 

		<el-pagination
		  background
		  layout="prev, pager, next"
		  :total="tikus.count" style="margin-bottom: 50px;" v-if="this.tikus.count>this.pageSize"
		  @current-change="onPaginationPageClick">
		</el-pagination>

	</div>
</template>

<script>
	import { get, post } from "@/components/Http"
	import modal from '@/components/Dialog.vue'
	import {contactInfo} from "@/GlobalData"

	export default{

		created(){
			this.onChangePage(1);
		},

		components: {
			modal
		},

		data(){
			return {
				imgEmpty: require('../resource/empty.png'),
				imgDownload: require('../resource/download.png'),
				imgError: require('../resource/Error.png'),
				imgCopy: require('../resource/copy.png'),
				imgLoading: require('../resource/loading.png'),
				isMultiDelete: false,
				checkList: {},
				pageNum: 0,
				pageSize: 10,
				timer: null,
				error: {
					show: false,
					msg: ""
				},
				contactShow: false,
				tikus: {
					list: []
				}
			}
		},

		computed: {
			contactInfo: {
				get(){
					return contactInfo;
				}
			}
		},

		methods: {
			onBtnCopyClick(taskId){
				get("/api/tiku/geturl", {
					taskId: taskId
				}, {
					Sessionid: localStorage.getItem("sessionId")
				})
				.then(res => {
					if(res.errNo > 0){
						console.log(res.errMsg);
						return;
					}

					navigator.clipboard.writeText(res.data.url);
					this.$message({
						message: "复制成功",
						type: "success"
					});
				})
			},

			onBtnDownloadClick(taskId){
				get("/api/tiku/geturl", {
					taskId: taskId
				}, {
					Sessionid: localStorage.getItem("sessionId")
				})
				.then(res => {
					if(res.errNo > 0){
						console.log(res.errMsg);
						return;
					}
					window.open(res.data.url, "_blank");
				})
			},

			onPaginationPageClick(pageNum){
				this.checkList = {};
				this.onChangePage(pageNum);
				this.isMultiDelete = false;
			},

			onChangePage(pageNum){
				let sessionId = localStorage.getItem("sessionId");
				if(sessionId == null || sessionId == undefined){
					return;
				}

				get("/api/task/list", {
					status: -1,
					pageNum: pageNum - 1,
					pageSize: this.pageSize
				}, {
						Sessionid: sessionId
					})
				.then(res => {
					if(res.errNo > 0){
						return;
					}

					if(res.data.list.length <= 0 && pageNum > 1){
						this.onChangePage(pageNum - 1);
						return;
					}
					
					this.tikus = res.data;
					this.pageNum = pageNum;
					var needLoop = false;
					for(var i = 0; i < this.tikus.list.length; i++){
						if(undefined == this.checkList[this.tikus.list[i].id]){
							this.checkList[this.tikus.list[i].id] = false;
						}
						if(this.tikus.list[i].status != 2 && this.tikus.list[i].status != 3){
							needLoop = true;
						}
					}

					if(!needLoop){
						window.clearInterval(this.timer);
						this.timer = null;
					} else {
						if(null == this.timer){
							this.timer = window.setInterval(() => {
								this.onChangePage(this.pageNum);
							}, 10000);
						}
					}
				})
			},

			onBtnErrorClick(res_msg){
				if(null == res_msg || undefined == res_msg || res_msg == ""){
					this.error.msg = "转换失败，请重试";
				} else {
					this.error.msg = res_msg;
				}
				this.error.show = true;
			},

			onBtnContactClick(){
				this.contactShow = true;
			},

			hideModal(){
				this.error.show = false;
			},

			hideContact(){
				this.contactShow = false;
			},

			onBtnDeleteClick(id){
				this.$confirm("是否确定删除该记录？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
				.then(() => {
					let sessionId = localStorage.getItem("sessionId");
					if(sessionId == null || sessionId == undefined){
						return;
					}
					post("/api/task/delete", {
						ids: id
					}, {
						headers: {
							Sessionid: sessionId
						}
					})
					.then(res => {
						if(res.errNo > 0){
							this.$message.error({
								message: res.errMsg
							})
						} else {
							this.$message({
								message: "成功删除" + res.data.count + "条数据",
								type: "success"
							})
							this.onChangePage(this.pageNum);
						}
					})
				})
				.catch(() => {

				})
			},

			onBtnMultiDeleteClick(){
				if(this.isMultiDelete){
					this.isMultiDelete = false;
				} else {
					this.isMultiDelete = true;
				}
			},

			onBtnMultiDeleteOKClick(){
				var delList = [];
				for(let id in this.checkList){
					if(this.checkList[id]){
						delList.push(id);
					}
				}
				if(delList.length <= 0){
					this.$message.error("请选择需要删除的记录");
					return;
				}
				this.$confirm("是否确定删除这" + delList.length + "条记录？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				})
				.then(res => {
					let sessionId = localStorage.getItem("sessionId");
					if(sessionId == null || sessionId == undefined){
						this.$message.error("未登录");
						return;
					}

					var ids = delList.join(',');

					post("/api/task/delete", {
						ids: ids
					}, {
						headers: {
							Sessionid: sessionId
						}
					})
					.then(res => {
						this.$message({
							message: "成功删除" + res.data.count + "条数据",
							type: "success"
						})
						this.checkList = {};
						this.onChangePage(this.pageNum);
					})
				})
				.catch(res => {

				})
			},

			onBtnMultiDeleteAllClick(){
				for(var id in this.checkList){
					this.checkList[id] = true;
				}
			},

			onBtnMultiDeleteAllNotClick(){
				for(var id in this.checkList){
					this.checkList[id] = false;
				}
			}
		},

		destroyed() {
			if(null != this.timer){
				window.clearInterval(this.timer);
				this.timer = null;
			}
		},

		beforeRouteLeave(to, from, next){
			if(null != this.timer){
				window.clearInterval(this.timer);
				this.timer = null;
			}
			next();
		}
	}
</script>

<style scoped>

.background_empty{
	width: 100%; 
	height: 100%; 
	display: flex; 
	flex-direction: column; 
	justify-content: center; 
	align-items: center;
}

.background_not_empty{
	width: 100%; 
	display: flex; 
	flex-direction: column; 
	justify-content: center; 
	align-items: center;
}
	
</style>




















