
// import HomePage from '/src/components/HomePage.vue'

// export const routes = [
  
//   {
//     path: '/home',
//     name: 'home',
//     component: HomePage
//   }
// ]


import { createRouter, createWebHashHistory } from 'vue-router'
import home from '@/components/HomePage.vue'
import login from '@/components/UserLogin.vue'
import addvip from '@/components/AddVip.vue'
import myMake from '@/components/MyMake.vue'
import tiku from '@/components/tiku.vue'
import purchase from '@/components/Purchase.vue'
import test from '@/components/test.vue'
import register from '@/components/Register.vue'
import pwlogin from '@/components/PWLogin.vue'
import qqcallback from '@/components/QQCallback.vue'
import shareTiku from '@/components/ShareTiku.vue'

const routes = [
  {
    path: '/home',
    component: home,
    name: "home",
  },
  // {
  //   path: '/',
  //   redirect: '/home',
  // },
  {
    path: '/login',
    component: login,
  },
  {
    path: '/addvip',
    component: addvip,
  },
  {
    path: '/mymake',
    component: myMake,
  },
  {
    path: '/tiku',
    component: tiku,
    name: "tiku",
  },
  {
    path: '/purchase',
    component: purchase,
    name: "purchase",
  },
  {
    path: '/test',
    component: test,
    name: "test",
  },
  {
    path: '/register',
    component: register,
    name: 'register',
  },
  {
    path: '/pwlogin',
    component: pwlogin,
    name: "pwlogin",
  },
  {
    path: '/qqcallback',
    component: qqcallback,
    name: "qqcallback",
  },
  {
    path: '/sharetiku5',
    component: shareTiku,
    name: "sharetiku",
  }
]

export default createRouter({
  history: createWebHashHistory(),
  routes,
})