<template>
	<div style="display: flex; flex-direction: column; align-items: center;">
		<div style="display: flex; justify-content: center; margin-top: 80px;">
			<div style="width: 198px; height: 180px; background-color: white; border-radius: 20px; display: flex; flex-direction: column; align-items: center; justify-content: center; cursor: pointer; position: relative;" @click="onBtnMonthClick" :class="product.current==1 ? 'productSelect' : 'productUnSelect'">
				<div style="font-weight: 400; font-size: 17px;">
					月卡
				</div>

				<div style="display: flex; margin-top: 5px;">
					<div style="font-weight: 400; font-size: 13px; align-self: flex-end; margin-bottom: 7px; color: rgba(229, 97, 110, 1)">
						¥
					</div>

					<div style="font-weight: 400; font-size: 30px; align-self: flex-end; color: rgba(229, 97, 110, 1)">
						{{product.month.amount}}
					</div>
				</div>

				<div style="width: 80%; height: 1px; background-color: rgba(223, 238, 254, 1); margin-top: 5px;">
					
				</div>

				<div style="font-weight: 300; font-size: 8px; color: rgba(79, 85, 91, 1); margin-top: 20px; margin-left: 10px; margin-right: 10px;">
					会员专享：30天无广告，智能制作题库
				</div>

				<!-- <div style="font-weight: 300; font-size: 8px; color: rgba(79, 85, 91, 1); margin-top: 7px;">
					这里是文字这里是文字
				</div> -->

				<el-image :src="product.imgSelect" style="width: 30px; height: 30px; position: absolute; right: 0; bottom: 0" v-if="product.current==1"></el-image>
			</div>

			<div style="width: 198px; height: 180px; background-color: white; border-radius: 20px; margin-left: 25px; display: flex; flex-direction: column; align-items: center; justify-content: center; cursor: pointer; position: relative;" @click="onBtnSeasonClick" :class="product.current==2 ? 'productSelect' : 'productUnSelect'">
				<div style="font-weight: 400; font-size: 17px;">
					季卡
				</div>

				<div style="display: flex; margin-top: 5px;">
					<div style="font-weight: 400; font-size: 13px; align-self: flex-end; margin-bottom: 7px; color: rgba(229, 97, 110, 1)">
						¥
					</div>

					<div style="font-weight: 400; font-size: 30px; align-self: flex-end; color: rgba(229, 97, 110, 1)">
						{{product.season.amount}}
					</div>
				</div>

				<div style="width: 80%; height: 1px; background-color: rgba(223, 238, 254, 1); margin-top: 5px;">
					
				</div>

				<div style="font-weight: 300; font-size: 8px; color: rgba(79, 85, 91, 1); margin-top: 20px; margin-left: 10px; margin-right: 10px;">
					会员专享：90天无广告，智能制作题库
				</div>

				<!-- <div style="font-weight: 300; font-size: 8px; color: rgba(79, 85, 91, 1); margin-top: 7px;">
					这里是文字这里是文字
				</div> -->

				<el-image :src="product.imgSelect" style="width: 30px; height: 30px; position: absolute; right: 0; bottom: 0" v-if="product.current==2"></el-image>
			</div>

			<div style="width: 198px; height: 180px; background-color: white; border-radius: 20px; margin-left: 25px; display: flex; flex-direction: column; justify-content: center; align-items: center; cursor: pointer; position: relative;" @click="onBtnYearClick" :class="product.current==3 ? 'productSelect' : 'productUnSelect'">
				<div style="font-weight: 400; font-size: 17px;">
					年卡
				</div>

				<div style="display: flex; margin-top: 5px;">
					<div style="font-weight: 400; font-size: 13px; align-self: flex-end; margin-bottom: 7px; color: rgba(229, 97, 110, 1)">
						¥
					</div>

					<div style="font-weight: 400; font-size: 30px; align-self: flex-end; color: rgba(229, 97, 110, 1)">
						{{product.year.amount}}
					</div>
				</div>

				<div style="width: 80%; height: 1px; background-color: rgba(223, 238, 254, 1); margin-top: 5px;">
					
				</div>

				<div style="font-weight: 300; font-size: 8px; color: rgba(79, 85, 91, 1); margin-top: 20px; margin-left: 10px; margin-right: 10px;">
					会员专享：365天无广告，智能制作题库
				</div>

				<!-- <div style="font-weight: 300; font-size: 8px; color: rgba(79, 85, 91, 1); margin-top: 7px;">
					这里是文字这里是文字
				</div> -->

				<el-image :src="product.imgSelect" style="width: 30px; height: 30px; position: absolute; right: 0; bottom: 0" v-if="product.current==3"></el-image>
			</div>
		</div>

		<div style="height: 1px; width: 50%; background-color: rgba(223, 238, 254, 1); margin-top: 70px;">
			
		</div>

		<div style="display: flex; justify-content: center; margin-top: 50px; margin-bottom: 50px; font-size: 20px; font-weight: 400">
			<div>
				应付金额:
			</div>

			<div style="color: rgba(229, 97, 110, 1)">
				&nbsp{{shouldPayAmount}}元
			</div>
		</div>

		<div style="display: flex; justify-content: center;">
			<div style="display: flex; flex-direction: column; align-items: center;">
				<div style="border-width: 2px; border-color: rgba(34, 206, 118, 1); border-radius: 10px; width: 110px; height: 110px; border-style: solid; display: flex; justify-content: center; align-items: center;">
					<div style="width: 100px; height: 100px;" ref="wechatpay"></div>
				</div>

				<div style="display: flex; margin-top: 20px;">
					<el-image :src="imgWeixin" style="width: 24.02px; height: 24.02px;"></el-image>
					<div style="font-weight: 400; font-size: 15px; margin-left: 10px;">微信支付</div>
				</div>
			</div>

			<!-- <div style="display: flex; flex-direction: column; align-items: center; margin-left: 80px;">
				<div style="border-width: 2px; border-color: rgba(24, 144, 255, 1); border-radius: 10px; width: 110px; height: 110px; border-style: solid;">
					
				</div>

				<div style="display: flex; margin-top: 20px; justify-content: center;">
					<el-image :src="imgAllipay" style="width: 24.02px; height: 24.02px;"></el-image>
					<div style="font-weight: 400; font-size: 15px; margin-left: 10px;">支付宝支付</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
	import { post } from "@/components/Http"
	import { get } from "@/components/Http"
	import QRCode from "qrcodejs2"
	import router from '@/routes'

	export default{
		inject: ["userInfo"],

		data(){
			return {
				shouldPayAmount: 0,
				imgWeixin: require("@/resource/wechat.png"),
				imgAllipay: require("@/resource/allipay.png"),
				product: {
					borderColor: "red",
					current: 2,
					imgSelect: require("@/resource/select.png"),
					month: {},
					season: {},
					year: {}
				},
				timer: null
			}
		},

		mounted(){
			this.onBtnSeasonClick();

			if(!this.userInfo["isLogin"]){
				router.replace("/login");
			}

			get("/api/product/list")
			.then(res => {
				if(res.errNo > 0){
					console.log(res.errMsg);
					return;
				}
				for(var i = 0; i < res.data.length; i++){
					if(res.data[i].id == this.product.current){
						this.shouldPayAmount = res.data[i].amount;
						break;
					}
				}
				this.product.month = res.data[0];
				this.product.season = res.data[1];
				this.product.year = res.data[2];
			})
		},

		methods:{
			onBtnMonthClick(){
				this.product.current = 1;
				this.shouldPayAmount = this.product.month.amount;

				this.setOrder();
			},


			onBtnSeasonClick(){
				this.product.current = 2;
				this.shouldPayAmount = this.product.season.amount;

				this.setOrder();
			},

			onBtnYearClick(){
				this.product.current = 3;
				this.shouldPayAmount = this.product.year.amount;

				this.setOrder();
			},

			setOrder(){
				let sessionId = localStorage.getItem("sessionId");
				post("/api/weixin/purchase", {productId: this.product.current}, {
					headers: {sessionId: sessionId}
				})
				.then(res => {
					if(this.$route.path != "/purchase"){
						return;
					}
      		this.$refs.wechatpay.innerHTML = "";
					if(res.errNo > 0){
						return;
					}
					var wxQrCode = new QRCode(this.$refs.wechatpay, {
	          text: res.data.codeUrl,
	          width: 100,
	          height: 100
	        });

					if(null == this.timer){
						this.timer = window.setInterval(() => {
		        	post("/api/weixin/checkpay", {productId: this.product.current}, {
		        		headers: {Sessionid: sessionId}
		        	})
		        	.then(res => {
		        		if(res.errNo > 0){
		        			console.log("出错啦～");
		        			return;
		        		}

		        		if(res.data.isPay == 1){
		        			window.clearInterval(this.timer);
									this.timer = null;
									setTimeout(function(){
										window.open("http://souti.ixyzh.com/#/home", "_self");
		        				router.go(0)
									}, 1000);
									
		        		}
		        	})
		        }, 3000);
					}
	        
				})
			}
		},

		destroyed() {
			if(null != this.timer){
				window.clearInterval(this.timer);
				this.timer = null;
			}
		},

		beforeRouteLeave(to, from, next){
			if(null != this.timer){
				window.clearInterval(this.timer);
				this.timer = null;
			}
			next();
		}
	}
</script>

<style scoped>

.productSelect{
	border-color: rgba(229, 97, 110, 1);
	border-width: 1px;
	border-style: solid;
}

.productUnSelect{
	border-color: white;
	border-width: 1px;
	border-style: solid;
}
	
</style>



























