<template>
  <div>
    <div
      class="box"
      :class="{ actives: isActive === 'red' }"
      @click="changeColor('red')"
    >
      <!-- 点击该div时边框变红色 -->
      Red Box
    </div>
    <div
      class="box"
      :class="{ actives: isActive === 'blue' }"
      @click="changeColor('blue')"
    >
      <!-- 点击该div时边框变蓝色 -->
      Blue Box
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: null, // 用于存储当前活跃的颜色
    };
  },
  methods: {
    changeColor(color) {
      // 点击某个div时，更新isActive以便设置相应的边框颜色
      this.isActive = color;
    },
  },
};
</script>

<style>
.box {
  width: 100px;
  height: 100px;
  border: 2px solid transparent; /* 初始时边框颜色透明 */
  margin: 10px;
  text-align: center;
  line-height: 100px;
  cursor: pointer;
}

.actives {
  border-color: red; /* 边框颜色为红色 */
}
</style>