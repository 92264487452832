<template>
	<div style="display: flex; justify-content: center; align-items: center; height: 100%;">
		<div style="width: 451px; height: 495px; background-color: white; border-radius: 20px; display: flex; flex-direction: column; align-items: center; justify-content: center;">
			<el-image :src="require('@/resource/icon.png')" style="width: 44px; height: 44px;"></el-image>
			<div style="font-weight: 500; font-size: 28px; color: rgba(51, 51, 51, 1); margin-top: 20px;">小包搜题</div>

			<div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">
				<el-image :src="require('@/resource/welcome.png')" style="width: 32px; height: 25px;"></el-image>
				<div style="font-weight: 500; font-size: 28px; color: rgba(51, 51, 51, 1)">欢迎登录后使用一键制作题库</div>
			</div>

			<div style="width: 80%; height: 46px; background-color: rgba(34, 206, 118, 1); margin-top: 80px; border-radius: 8px; display: flex; justify-content: center; align-items: center; cursor: pointer;" @click="onWeixinLoginClick">
				<el-image :src="require('@/resource/weixin.png')" style="width: 25px; height: 25px;"></el-image>
				<div style="color: white; font-weight: 350; font-size: 15px;">
					微信登录
				</div>
			</div>

			<div style="width: 80%; height: 46px; background-color: rgba(51, 153, 255, 1); margin-top: 20px; border-radius: 8px; display: flex; justify-content: center; align-items: center; cursor: pointer;" @click="onQQLoginClick">
				<el-image :src="require('@/resource/qq.png')" style="width: 25px; height: 25px;"></el-image>
				<div style="color: white; font-weight: 350; font-size: 15px;">
					QQ登录
				</div>
			</div>

			<!-- <div style="width: 80%; height: 46px; background-color: purple; margin-top: 20px; border-radius: 8px; display: flex; justify-content: center; align-items: center; cursor: pointer;" @click="onUserPasswordClick">
				<div style="color: white; font-weight: 350; font-size: 15px;">
					用户名密码登录
				</div>
			</div> -->

			<!-- <el-link @click="onBtnRegisterClick" style="margin-top: 20px; color: blue;">注册</el-link> -->
		</div>
	</div>
</template>

<script>
	import router from "@/routes"


	export default{
		created(){
			window.addEventListener('message', this.onQQCallback, false);
		},

		data(){
			return {}
		},

		methods: {
			onQQCallback(e){
				if(undefined != e.data.sessionId && null != e.data.sessionId){
					localStorage.setItem("sessionId", e.data.sessionId);
        	location.reload();
				}
			},

			onBtnRegisterClick(){
				router.push("/register");
			},

			onUserPasswordClick(){
				router.push("/pwlogin");
			},

			onWeixinLoginClick(){
				// window.location.href = "https://open.weixin.qq.com/connect/qrconnect?appid=wxafba05f35910b72d&redirect_uri=" + window.location.href + "&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect";
				let url = "https://open.weixin.qq.com/connect/qrconnect?appid=wxafba05f35910b72d&redirect_uri=" + encodeURI("http://souti.ixyzh.com/") + "&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect"
				window.location.href = url;
			},

			onQQLoginClick(){
				let windowWidth = 600;
				let windowHeight = 400;
				window.open("https://graph.qq.com/oauth2.0/authorize?client_id=102069720&redirect_uri=https://souti.ixyzh.com/qqcallback&response_type=code&state=123aab", "_blank", "width=" + windowWidth + ",height=" + windowHeight + ",top=" + (window.screen.height - windowHeight) / 2 + ",left=" + (window.screen.width - windowWidth) / 2);
			}
		}
	}

</script>

<style scoped>

</style>















