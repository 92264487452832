<template>
	<div style="display: flex; background-color: rgba(1, 1, 1, 0.7); width: 100%; height: 100%;">
		<el-image :src="imgWechatLead" style="position: absolute; right: 0; top: 0;" v-if="isInWeixin"></el-image>

	</div>
</template>

<script>
	import { get } from "@/components/Http"

	export default{
		data(){
			return {
				isInWeixin: false,
				imgWechatLead: require("../resource/wechatLead.png")
			}
		},

		methods: {
			
		},

		created() {
			var id = "";
			let arrUrl = location.href.split("?id=");
			if(arrUrl.length == 2){
				id = arrUrl[1];
			}
			let ua = navigator.userAgent.toLowerCase();
			this.isInWeixin = (ua.indexOf('micromessenger') > -1);
      let isAndroid = ua.indexOf('android') > -1 || ua.indexOf('Adr') > -1;  
      let isiOS = /(iphone|ipad|ipod|ios)/i.test(ua);

      if(isAndroid){
      	window.open("weixin://", "_self");
      } else if(isiOS){
      	window.open("wx485f31455e1e5888://" + id, "_self");
      	setTimeout(function(){
					window.open("itms-apps://itunes.apple.com/cn/app/jie-zou-da-shi/1600237719");
				}, 3000);
      }
		}
	}
	
</script>

<style>
	
</style>