<template>
	<div></div>
</template>

<script>
  import tools from '@/components/Tools'

	export default{
		created(){
			if(null != window.opener){
        console.log("welcome !!!!!!!");
      	let sessionId = tools.getUrlKey("sessionId");
        window.opener.postMessage({sessionId: sessionId}, "https://souti.ixyzh.com");
        window.open('','_self').close();
        return;
      }
		}
	}
</script>

<style>
	
</style>